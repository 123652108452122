import { isEmpty } from 'lodash';
import { File, Trash } from 'phosphor-react';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
//API and Hook Imports
import FileTemplateApi from '../../api/FileTemplateApi';
//Helper Imports
import { getArtifactName } from '../../helpers/ArtifactNameHelper';
import { fileSizeFormatter, isValidValue, regexNumericValueControl } from '../../helpers/Helper';
import { INFO_MESSAGES, showError, showErrorMes, showSuccessMes } from '../../helpers/NotificationHelper';
import { useApi } from '../../hooks/UseApi';
import UseProjectConfig from '../../hooks/UseProjectConfig';
import ConfirmationModal from '../confirm/ConfirmationModal';
//Desing System Imports
import { Dropdown, Button, Body, H6, Heading } from '../../designSystem/DesignSystem';
import { Modal } from '../modal';
//Component Imports
import AttachmentUpload from '../upload/AttachmentUpload';
import { useTranslation } from 'react-i18next';
import { translateArrItemName } from '../../helpers/TranslationHelper';

const ImportFileModal = ({ isOpen, closeModal, entityType, projectId }) => {
  const { control, handleSubmit, setValue } = useForm({ mode: 'onBlur', shouldFocusError: true, criteriaMode: 'all' });

  const projectConfig = UseProjectConfig(projectId);
  const [selectedFile, setSelectedFile] = useState('');
  const [fields, setFields] = useState([]);
  const [fieldTemplate, setFieldTemplate] = useState([]);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [clear, setClear] = useState(true);
  const [excelValidation, setExcelValidation] = useState({});
  const [excelValidationModalOpen, setExcelValidationModalOpen] = useState(false);
  const { t } = useTranslation();

  const downloadTemplate = useApi({
    name: 'Download template',
    api: FileTemplateApi.downloadTemplate,
    onSuccess: () => {
      const fileName = entityType + '-template.xlsx';
      const url = window.URL.createObjectURL(new Blob([downloadTemplate?.data], { type: 'application/vnd.ms-excel' }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
    },
    onError: () => showError('Could not download file.')
  });

  const getFieldTemplate = useApi({
    name: 'get field template',
    api: FileTemplateApi.getFieldTemplate,
    onSuccess: () => {
      setFieldTemplate(
        getFieldTemplate?.data.map((item) => {
          if (!item.value) {
            const field = fields.find((field) => field.value === item.displayName);
            if (field) {
              return { ...item, value: field.value };
            } else {
              return item;
            }
          } else {
            return item;
          }
        })
      );
    },
    onError: () => showError('Failed to get template information.')
  });

  const parseTemplate = useApi({
    name: 'parse template file',
    api: FileTemplateApi.parseTemplate,
    onSuccess: () => {
      setFields(setOptions(parseTemplate?.data));
    },
    onError: (err) => {
      setFields([]);
      if (err?.response?.data?.message) {
        showError(t(err?.response?.data?.message));
      } else {
        showError('File could not be parsed.');
      }
    }
  });

  const importTemplate = useApi({
    name: 'import template',
    api: FileTemplateApi.importTemplate,
    onSuccess: () => {
      showSuccessMes(INFO_MESSAGES.import);
      setTimeout(() => window.location.reload(), 1000);
    },
    onError: (err) => {
      if (err?.response?.data?.message.startsWith('The number of rows')) {
        let numericValue = regexNumericValueControl(err.response.data.message);
        showError(
          t('maxExcelRowLimitationErrorMessage', {
            rowNumber: numericValue
          })
        );
      } else if (err?.response?.data?.message?.startsWith('Unmatched columns found')) {
        let unmatchedColumnNames = err?.response?.data?.message?.split(':')[1];
        showError(t('excelImportUnmatchedColumnErrorMessage', { columnNames: unmatchedColumnNames }));
      } else {
        showErrorMes(err);
      }
      setExcelValidation(err?.response?.data?.excelValidation);
    }
  });

  const setOptions = (data) => {
    if (data != null && data.length !== 0) {
      const list = [];
      data.forEach((item) => {
        list.push({ key: item, value: item, text: item, name: item });
      });
      return list;
    }
    return [];
  };

  const onSubmit = () => {
    const config = {
      headers: { 'content-type': 'multipart/form-data' }
    };

    fileList.forEach(async (file) => {
      const data = new FormData();
      const template = [...fieldTemplate];
      data.append('file', file);
      data.append('type', entityType);
      data.append('template', JSON.stringify(template));
      setSelectedFile(file);

      importTemplate.execute(data, config);
    });
  };

  const getTemplate = () => {
    downloadTemplate.execute(entityType);
  };

  const uploadFunction = (files) => {
    setFileList(files);

    const config = {
      headers: { 'content-type': 'multipart/form-data' }
    };

    files.forEach(async (file) => {
      const data = new FormData();

      if (file.size > 10485760) {
        showError('File is bigger than 10 mb.');
        setSelectedFile('');
      } else {
        data.append('file', file);
        setSelectedFile(file);
        parseTemplate.execute(data, config);
      }
    });
  };

  const clearTemp = () => {
    const newList = [...fieldTemplate];
    newList.forEach((field) => {
      field.value = '';
      setValue(field.name, '');
    });
    setClear(true);
  };

  const clearValidation = () => {
    fieldTemplate.forEach((field) => {
      if (field.value !== '') {
        setClear(false);
      }
    });
  };

  const onChange = (value, index) => {
    const newList = [...fieldTemplate];
    const temp = fieldTemplate[index];
    newList.forEach((item) => {
      if (item.name === temp.name) {
        item.value = value[0].value;
      }
    });
    setFieldTemplate(newList);
  };

  const onDeleteFile = () => {
    setConfirmOpen(false);
    setSelectedFile('');
    setFields([]);
  };

  useEffect(() => {
    getFieldTemplate.execute(entityType);
  }, [fields]);

  useEffect(() => {
    clearValidation();
  }, [fieldTemplate]);

  useEffect(() => {
    if (!isEmpty(excelValidation)) {
      setExcelValidationModalOpen(true);
    }
  }, [excelValidation]);
  let fieldsOptions = translateArrItemName(fields, t);

  return (
    <>
      <Modal
        open={isOpen}
        close={() => closeModal}
        w={'md'}
      >
        <Modal.Title>
          {t('import_entity', {
            entity: getArtifactName(projectConfig, entityType)
          })}
        </Modal.Title>
        <form
          className=""
          onSubmit={handleSubmit(onSubmit)}
        >
          <Modal.Content h={'md'}>
            <div>
              <H6
                text={t(
                  'Select the excel file you want to import from your local drive. Only the format of the first selected page will be used for field matching.'
                )}
                color={'text-gray-500'}
              />
              <H6
                text={t('Therefore, make sure that all files are consistently formatted.')}
                color={'text-gray-500'}
              />
              <div className="flex flex-row gap-1">
                <H6
                  text={t('You can use this')}
                  color={'text-gray-500'}
                />
                <a
                  className={'cursor-pointer text-blue-600'}
                  onClick={() => getTemplate()}
                >
                  <H6
                    text={t('Sample Import template')}
                    color={'text-blue-500'}
                  />
                </a>
                <H6
                  text={t('import_if_entity', { entity: entityType })}
                  color={'text-gray-500'}
                />
              </div>

              <H6
                text={t("Use '->' characters for foldering. ( MainFolder '->' FirstSubFolder '->' SecondSubFolder )")}
                color={'text-gray-500'}
              />
            </div>
            <div className="h-96 flex-grow flex-col">
              <AttachmentUpload
                projectId={projectId}
                entityType={entityType}
                uploadFunction={uploadFunction}
              />
              {isValidValue(selectedFile) && (
                <>
                  <div className="flex items-center gap-4 py-4">
                    <div
                      id="attachment-thumbnail"
                      className="flex h-16 w-16 items-center justify-center rounded-md bg-gray-100"
                    >
                      <File
                        weight="duotone"
                        color="#94A3B8"
                        size="32"
                      />
                    </div>
                    <div
                      id="attachment-metadata"
                      className="flex flex-col justify-between space-y-2"
                    >
                      <div
                        id="attachment-metadata-name"
                        className="text-sm font-semibold"
                      >
                        {selectedFile.name}
                      </div>

                      <div
                        id="attachment-metadata-size"
                        className="text-xs text-gray-500"
                      >
                        {fileSizeFormatter(selectedFile.size)}
                      </div>
                    </div>
                    <div
                      id="attachment-buttons"
                      className="flex gap-4"
                    >
                      <Trash
                        size="24"
                        weight="duotone"
                        onClick={() => setConfirmOpen(true)}
                      />
                    </div>
                  </div>
                  {fieldsOptions.length !== 0 && (
                    <div className="grid flex-grow grid-cols-2 gap-4">
                      {fieldTemplate.length > 0 &&
                        fieldTemplate.map((fieldTemp, index) => {
                          return (
                            <div key={index}>
                              <Dropdown
                                clearable
                                required={fieldTemplate[index]?.required}
                                control={control}
                                name={fieldTemplate[index]?.name}
                                label={t(fieldTemplate[index]?.displayName)}
                                options={fieldsOptions}
                                selectedItem={
                                  isValidValue(fieldTemplate[index]?.value)
                                    ? fieldsOptions.filter((item) => item.value === fieldTemplate[index]?.value)[0]
                                    : ''
                                }
                                passValue={(value) => onChange(value, index)}
                              />
                            </div>
                          );
                        })}
                    </div>
                  )}
                </>
              )}
            </div>
          </Modal.Content>
          <Modal.Buttons>
            <Button
              id="importFileModal-cancel-button"
              secondary
              text={t('Cancel')}
              onClick={() => {
                closeModal();
              }}
            />
            {isValidValue(selectedFile) && fields.length !== 0 && (
              <>
                <Button
                  id="importFileModal-clear-button"
                  secondary
                  text={t('Clear')}
                  disabled={clear}
                  onClick={() => clearTemp()}
                />
                <Button
                  id="importFileModal-import-button"
                  primary
                  text={t('Import')}
                  onClick={() => onSubmit()}
                  loading={importTemplate?.loading}
                />
              </>
            )}
          </Modal.Buttons>
        </form>
      </Modal>

      {
        <ConfirmationModal
          isOpen={confirmOpen}
          closeModal={() => setConfirmOpen(false)}
          handleConfirm={onDeleteFile}
          header={t('Confirm Delete')}
          content={t('Are you sure you wat to delete the file?')}
        />
      }

      {excelValidationModalOpen && (
        <Modal
          open={excelValidationModalOpen}
          close={() => setExcelValidationModalOpen(false)}
        >
          <Modal.Title>
            <Heading.Group
              text={t('Excel Validation Error')}
              fontSize="small"
            >
              <Button
                id="file-error-close-button"
                icon="X"
                onClick={() => { setExcelValidationModalOpen(false) }}
                size="xsmall"
              />
            </Heading.Group>
          </Modal.Title>
          <Modal.Content>
            <table className="w-full border-collapse text-left">
              <thead>
                <tr className="h-8">
                  <th className="border-grey-light border-b">
                    <Body
                      type="medium"
                      text="Field"
                      bold
                    />
                  </th>
                  <th className="border-grey-light border-b">
                    <Body
                      type="medium"
                      text={t('Error')}
                      bold
                    />{' '}
                  </th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(excelValidation).map((field, index) => {
                  return (
                    <tr
                      key={index}
                      className="hover:bg-grey-lighter h-8"
                    >
                      <td className="border-grey-light border-b">
                        <Body
                          type="medium"
                          text={field.charAt(0).toUpperCase() + field.slice(1)}
                        />
                      </td>
                      <td className="border-grey-light border-b">
                        <Body
                          type="medium"
                          text={excelValidation[field]}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </Modal.Content>
        </Modal>
      )}
    </>
  );
};
export default ImportFileModal;
