import React, { useState, useRef } from 'react';
import { Controller } from 'react-hook-form';
import { classNames } from '../../helpers/Helper';

function MultiTextWrapper({
    value = [],
    onChange = () => { },
    placeholder = 'Enter one or more values...',
    disabled = false,
    ...props
}) {
    const [inputValue, setInputValue] = useState('');
    const inputRef = useRef(null);

    // On Enter or comma, create a new pill
    const handleKeyDown = (e) => {
        if ((e.key === 'Enter' || e.key === ',') && inputValue.trim()) {
            e.preventDefault();
            const newValues = [...value, inputValue.trim()];
            onChange(newValues);
            setInputValue('');
        }
    };

    const removeValue = (val) => {
        const newValues = value.filter((item) => item !== val);
        onChange(newValues);
    };

    return (
        <div
            className={classNames(
                'relative flex min-h-[36px] w-full cursor-default flex-wrap rounded-md',
                'items-center border border-gray-300 bg-white py-1 pl-3 pr-10 text-left',
                'transition duration-150 ease-in-out sm:text-sm sm:leading-5',
                'focus:outline-none focus:ring-1 focus:ring-primary-600 focus:ring-offset-0'
            )}
            onClick={() => inputRef.current?.focus()}
            {...props}
        >
            {value.map((val, idx) => (
                <div
                    key={`${val}-${idx}`}
                    className={classNames(
                        !disabled
                            ? 'my-1 mr-1 inline-flex  flex-wrap items-center rounded-lg bg-primary-600 px-2 text-xs text-white'
                            : 'my-1 mr-1 inline-flex cursor-not-allowed flex-wrap items-center rounded-lg bg-primary-600 px-2 text-xs text-white'
                    )}
                >
                    {val}
                    {!disabled && (
                        <span
                            className={classNames(
                                'ml-1 cursor-pointer',
                                'hover:text-gray-200'
                            )}
                            onClick={() => removeValue(val)}
                        >
                            &times;
                        </span>
                    )}
                </div>
            ))}

            {!disabled && (
                <input
                    ref={inputRef}
                    className={classNames(
                        'border-none outline-none shadow-none bg-transparent text-sm flex-1',
                        'focus:outline-none focus:shadow-none',
                        'm-1 min-w-[80px]'
                    )}
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                    onKeyDown={handleKeyDown}
                    placeholder={placeholder}
                    disabled={disabled}
                />
            )}
        </div>
    );
}

/**
 * The exported component that integrates with react-hook-form via <Controller>.
 */
export function MultiTextInput({
    label,
    control,
    name,
    passValue,
    placeholder,
    disabled,
    required,
    ...props
}) {
    return (
        <Controller
            control={control}
            name={name}
            defaultValue={[]}
            render={({ field: { onChange, value } }) => (
                <div className='flex flex-grow flex-col p-1'>
                    {label && (
                        <label
                            htmlFor={label}
                            className={classNames(
                                !disabled ? 'text-gray-900' : 'text-gray-400',
                                'mb-2 block text-sm font-medium capitalize'
                            )}
                        >
                            {label}
                            {required && label && (
                                <span
                                    className={classNames(
                                        !disabled ? 'text-red-500' : 'text-gray-600',
                                        'ml-2'
                                    )}
                                >
                                    *
                                </span>
                            )}
                        </label>
                    )}
                    <MultiTextWrapper
                        value={value || []}
                        onChange={(vals) => {
                            onChange(vals);     
                            passValue?.(vals);  
                        }}
                        placeholder={placeholder}
                        disabled={disabled}
                        {...props}
                    />
                </div>
            )}
        />
    );
}
