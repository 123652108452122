import React, { useState, useEffect } from 'react';
import { useApi } from '../../../hooks/UseApi';
import CustomReportApi from '../../../api/CustomReportApi';
import UseJiraConfig from '../../../hooks/UseJiraConfig';
import UseProjectConfig from '../../../hooks/UseProjectConfig';
import UseQueryProject from '../../../hooks/UseQueryProject';

import { Modal } from '../../modal';
import { Heading, Button, Input, TextArea, MultiSelectDropdownPill } from '../../../designSystem/DesignSystem';
import PercentageBar from '../../general/PercentageBar';
import DefectPriorityAndStatusReport from '../general/DefectPriorityAndStatusReport';
import EntityDataTable from '../general/EntityDataTable';
import { REPORT_MAIL_SUBJECT } from '../../../constants';
import RequirementGeneralTestReportTable from './RequirementGeneralTestReportTable';
import { ENTITY_TYPES } from '../../../constants';
import { getDefectName, getRunName, getTestCaseName } from '../../../helpers/ArtifactNameHelper';
import { getStatusBarOptionsDynamic } from '../../../helpers/Helper';
import { showErrorMes, showSuccessMes } from '../../../helpers/NotificationHelper';
import { downloadExportingFile } from '../ReportHelper';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';

const headingSize = 'base';

const RequirementReportModal = ({ isOpen, closeModal, entityId, users }) => {
  const { projectId } = UseQueryProject('projectId');
  const projectConfig = UseProjectConfig(projectId);
  const jiraConfig = UseJiraConfig(projectId);
  const { t } = useTranslation();
  const [response, setResponse] = useState({});

  // mail related fields
  const [activeView, setActiveView] = useState('report');
  const [viewText, setViewText] = useState(t('Send Email'));
  const [loading, setLoading] = useState(false);
  const { control, handleSubmit } = useForm({ mode: 'onBlur', shouldFocusError: true, criteriaMode: 'all' });

  const sendReport = useApi({
    name: 'sendReport',
    api: CustomReportApi.sendReport,
    autoLoad: false,
    onSuccess: () => {
      showSuccessMes('Report was sent successfully.');
      closeModal();
    },
    onError: () => {
      showErrorMes('Mail could not be send.');
      setLoading(false);
    }
  });

  const onSubmitForMail = (form) => {
    setLoading(true);

    const emails = [];
    form?.emails?.forEach((item) => {
      emails.push(item?.email);
    });

    const entityType = ENTITY_TYPES.REQUIREMENT;

    const body = {
      entityId,
      entityType,
      emails: emails,
      subject: form?.subject,
      message: form?.message
    };

    showSuccessMes(t('Report is preparing to send.'));
    sendReport.execute(body);
  };

  const dataLoader = useApi({
    name: 'fetching ',
    api: CustomReportApi.getEntityReport,
    onSuccess: () => setResponse(dataLoader?.data),
    onError: (err) => showErrorMes(err)
  });

  const dowloadReport = useApi({
    name: 'dowloadReport',
    api: CustomReportApi.downloadEntityReport,
    autoLoad: false,
    onSuccess: () => downloadExportingFile(dowloadReport?.data, ENTITY_TYPES.REQUIREMENT),
    onError: (err) => showErrorMes(err)
  });

  useEffect(() => {
    dataLoader.execute({ entityId, entityType: ENTITY_TYPES.REQUIREMENT });
  }, [entityId]);

  return (
    <>
      <Modal
        w={'lg'}
        open={isOpen}
        close={() => closeModal}
      >
        <Modal.Title>
          <Heading.Group
            text={t('Requirement Report')}
            notCapitalize={true}
          >
            <div className="flex gap-4">
              <Button
                id="requirementReportModal-sendEmail-button"
                secondary
                text={viewText}
                onClick={() => {
                  if (activeView === 'report') {
                    setActiveView('other')
                    setViewText(t('Report View'))
                  } else {
                    setActiveView('report')
                    setViewText(t('Send Email'))
                  }
                }}
                size={'xsmall'}
              />
              <Button
                id="requirementReportModal-export-button"
                secondary
                text={t('Export')}
                icon="UploadSimple"
                onClick={() => {
                  showSuccessMes('Report is downloading');
                  dowloadReport.execute({ entityType: ENTITY_TYPES.REQUIREMENT, entityId });
                }}
                loading={dowloadReport?.loading}
                size={'xsmall'}
              />
              <Button
                id="requirementReportModal-close-button"
                icon="X"
                onClick={closeModal}
                size={'xsmall'}
              />
            </div>
          </Heading.Group>
        </Modal.Title>
        {activeView === 'report' ?
          ( // render report content view
            <Modal.Content h={'xl'}>
              {!response ? (
                'loading'
              ) : (
                <>
                  <div className="flex flex-grow flex-col gap-4">
                    {/* General Test Report  */}
                    <div className="flex flex-grow flex-col gap-4">
                      <Heading
                        type={headingSize}
                        text={t('General Test Report')}
                      />
                      <RequirementGeneralTestReportTable
                        response={response}
                        projectId={projectId}
                        projectConfig={projectConfig}
                      />
                    </div>
                    {/* Test Coverage  */}
                    <div className="flex flex-grow flex-col gap-4">
                      <Heading
                        type={headingSize}
                        text={t('Test Coverage')}
                      />
                      <PercentageBar
                        showLabel={true}
                        datas={getStatusBarOptionsDynamic(response?.entityJson?.coverage)}
                      />
                    </div>
                    {/* Defect Insight */}
                    <DefectPriorityAndStatusReport
                      response={response}
                      projectId={projectId}
                      projectConfig={projectConfig}
                      users={users}
                      jiraConfig={jiraConfig}
                      {...(jiraConfig?.activeDefect === true && { isJiraStatus: true })}
                    />
                    {/* Defect Report */}
                    <div className="flex flex-grow flex-col gap-4">
                      <Heading
                        type={headingSize}
                        text={getDefectName(projectConfig)}
                      />
                      <EntityDataTable
                        response={response?.defects}
                        projectId={projectId}
                        projectConfig={projectConfig}
                        users={users}
                        entityType={ENTITY_TYPES.DEFECT}
                        jiraConfig={jiraConfig}
                      />
                    </div>
                    {/* Test Run Report */}
                    <div className="flex flex-grow flex-col gap-4">
                      <Heading
                        type={headingSize}
                        text={getRunName(projectConfig)}
                      />
                      <EntityDataTable
                        response={response?.testRuns}
                        projectId={projectId}
                        projectConfig={projectConfig}
                        users={users}
                        entityType={ENTITY_TYPES.TEST_RUN}
                      />
                    </div>
                    {/* Test Case Report */}
                    <div className="flex flex-grow flex-col gap-4">
                      <Heading
                        type={headingSize}
                        text={getTestCaseName(projectConfig)}
                      />
                      <EntityDataTable
                        response={response?.testCases}
                        projectId={projectId}
                        projectConfig={projectConfig}
                        users={users}
                        entityType={ENTITY_TYPES.TEST_CASE}
                      />
                    </div>
                  </div>
                </>
              )}
            </Modal.Content>
          )
          :
          ( // render mail modal view
            <form
              className="flex flex-col gap-4"
              onSubmit={handleSubmit(onSubmitForMail)}
            >
              <Modal.Content h={'lg'}>
                <>
                  <div className="flex flex-grow flex-col gap-4">
                    <Heading
                      type={headingSize}
                      text={t('Send Report Email')} />

                    <div className="flex gap-4">
                      <MultiSelectDropdownPill
                        label={t('Users')}
                        control={control}
                        name={'emails'}
                        options={users}
                      />
                    </div>
                    <Input
                      name="subject"
                      label={t('Subject')}
                      control={control}
                      maxLength="255"
                      placeholder={t(REPORT_MAIL_SUBJECT)}
                      disabled
                    />
                    <TextArea
                      placeholder={t('Enter your text here')}
                      control={control}
                      name="message"
                      label={t('Message')}
                    />
                  </div>
                </>
              </Modal.Content>
              <Modal.Buttons>
                <Button
                  id="reportMailModal-submit-button"
                  primary
                  text={t('Send Report')}
                  type="submit"
                  loading={loading}
                />
              </Modal.Buttons>
            </form>
          )
        }
      </Modal>
    </>
  );
};

export default RequirementReportModal;
