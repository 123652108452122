import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button, Input, Toggle } from '../../../designSystem/DesignSystem';
import { classNames } from '../../../helpers/Helper';
import { useTranslation } from 'react-i18next';

const SystemParametersForm = ({ onSubmit, data, loading, defaultValues }) => {
  const { t } = useTranslation();

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    formState: { dirtyFields }
  } = useForm({ defaultValues, mode: 'onChange' });

  const [rateLimitIsActive, setRateLimitIsActive] = useState(false);

  function convertDataToForm(data, setValueFn) {
    if (!data) return;
    data.forEach((item) => {
      if (item.paramKey === 'rateLimitIsActive') {
        setValueFn(item.paramKey, item.paramVal === 'true' ? true : false);
      } else {
        setValueFn(item.paramKey, item.paramVal);
      }
    });
  }

  useEffect(() => convertDataToForm(data, setValue), [data, loading, rateLimitIsActive]);

  function getDataDescriptionByKey(dataItem) {
    if (!dataItem) return;
    return data?.find((item) => item.paramKey === dataItem.paramKey)?.description;
  }

  const handleSubmitForm = () => {
    Object.keys(dirtyFields).forEach((item) => {
      const originalData = data.find((dataItem) => dataItem.paramKey === item);
      originalData.paramVal = getValues(item);
      onSubmit(originalData);
    });

    if (defaultValues.rateLimitIsActive !== getValues().rateLimitIsActive) {
      const originalData = data.find((dataItem) => dataItem.paramKey === 'rateLimitIsActive');
      originalData.paramVal = rateLimitIsActive;
      onSubmit(originalData);
    }
  };

  const toggleValue = (value) => {
    setValue('rateLimitIsActive', value.toString());
    setRateLimitIsActive(value);
  };

  useEffect(() => {
    const initialRateLimit = data?.find((item) => item.paramKey === 'rateLimitIsActive')?.paramVal === 'true';
    setRateLimitIsActive(initialRateLimit);
  }, [data]);

  if (loading) return <div>Loading...</div>;
  return (
    <>
      <form
        id="systemParametersForm"
        className="flex w-1/4 flex-col gap-4 pl-2 pt-6"
        onSubmit={handleSubmit(handleSubmitForm)}
      >
        <div className="mb-1 block text-sm font-medium capitalize">{t('Rate Limit Is Active')}</div>
        <Toggle
          name="rateLimitIsActive"
          labelText={t('Rate Limit Is Active')}
          control={control}
          value={rateLimitIsActive}
          passValue={toggleValue}
        />
        <div className={classNames('text-sm', !rateLimitIsActive ? 'text-gray-200' : 'text-color-600')}>
          {getDataDescriptionByKey('rateLimitIsActive')}
        </div>
        <Input
          disabled={!rateLimitIsActive}
          name="rateLimitCapacity"
          label={t('Rate Limit Capacity')}
          control={control}
        />
        <div className={classNames('text-sm', !rateLimitIsActive ? 'text-gray-200' : 'text-color-600')}>
          {getDataDescriptionByKey('rateLimitCapacity')}
        </div>
        <Input
          disabled={!rateLimitIsActive}
          name="rateLimitRefillSeconds"
          label={t('Rate Limit Refill Seconds')}
          control={control}
        />
        <div className="text-color-600 text-sm">{getDataDescriptionByKey('rateLimitBanDuration')}</div>
        <Input
          disabled={!rateLimitIsActive}
          name="rateLimitBanDuration"
          label={t('Rate Limit Ban Duration')}
          control={control}
        />
        <div className="text-color-600 text-sm">{getDataDescriptionByKey('rateLimitRefillSeconds')}</div>
        <div className="border-b border-gray-200" />
        <Input
          name="bulkExecutionLimit"
          label={t('Bulk Execution Limit')}
          control={control}
        />
        <div className="text-color-600 text-sm">{getDataDescriptionByKey('bulkExecutionLimit')}</div>
        <Input
          name="maxExcelRowNumber"
          label={t('Max Excel Row Number')}
          control={control}
        />
        <div className="text-color-600 text-sm">{getDataDescriptionByKey('maxExcelRowNumber')}</div>
        <Input
          name="maxFileUploadSize"
          label={t('Max File Upload Size')}
          control={control}
        />
        <div className="text-color-600 text-sm">{getDataDescriptionByKey('maxFileUploadSize')}</div>
        <Input
          name="maxUploadSize"
          label={t('Max Upload Size')}
          control={control}
        />
        <div className="text-color-600 text-sm">{getDataDescriptionByKey('maxUploadSize')}</div>
        <Input
          name="attachmentUploadCount"
          label={t('Attachment Upload Count')}
          control={control}
        />
        <div className="text-color-600 text-sm">{getDataDescriptionByKey('attachmentUploadCount')}</div>
        <div id="systemParametersForm-submit">
          <Button
            loading={loading}
            // disabled={!isDirty}
            primary
            text={t('Save')}
            type="submit"
          />
        </div>
      </form>
    </>
  );
};

export default SystemParametersForm;
