import React, { useState, useEffect } from 'react';
// API & HOOKS
import CustomReportApi from '../../../api/CustomReportApi';
import { useApi } from '../../../hooks/UseApi';
import UseJiraConfig from '../../../hooks/UseJiraConfig';
import UseProjectConfig from '../../../hooks/UseProjectConfig';
import UseQueryProject from '../../../hooks/UseQueryProject';
// DESIGN SYSTEM & COMPONENTS
import PercentageBar from '../../general/PercentageBar';
import { Modal } from '../../modal';
import DefectPriorityAndStatusReport from '../general/DefectPriorityAndStatusReport';
import EntityDataTable from '../general/EntityDataTable';
import TesterExecutionInsight2 from './TesterExecutionInsight2.js';
import CreatedByDefectInsight from './CreatedByDefectInsight';
import { REPORT_MAIL_SUBJECT } from '../../../constants';

import { Heading, Button, Input, TextArea, MultiSelectDropdownPill } from '../../../designSystem/DesignSystem';
import { ENTITY_TYPES } from '../../../constants';
import { downloadExportingFile } from '../ReportHelper';
import { getArtifactName, getDefectName, getRunName } from '../../../helpers/ArtifactNameHelper';
import { getElasticRunStatusBarOptions } from '../../../helpers/Helper';
import { showErrorMes, showSuccessMes } from '../../../helpers/NotificationHelper';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';

const TestPlanningReportModal = ({ isOpen, closeModal, entityId, users, entityData, entityType }) => {
  const { t } = useTranslation();

  const { projectId } = UseQueryProject('projectId');
  const projectConfig = UseProjectConfig(projectId);
  const jiraConfig = UseJiraConfig(projectId);

  const [response, setResponse] = useState({});
  const headingSize = 'base';

  // mail related fields
  const [activeView, setActiveView] = useState('report');
  const [viewText, setViewText] = useState(t('Send Email'));
  const [loading, setLoading] = useState(false);
  const { control, handleSubmit } = useForm({ mode: 'onBlur', shouldFocusError: true, criteriaMode: 'all' });

  const sendReport = useApi({
    name: 'sendReport',
    api: CustomReportApi.sendReport,
    autoLoad: false,
    onSuccess: () => {
      showSuccessMes('Report was sent successfully.');
      closeModal();
    },
    onError: () => {
      showErrorMes('Mail could not be send.');
      setLoading(false);
    }
  });

  const onSubmitForMail = (form) => {
    setLoading(true);

    const emails = [];
    form?.emails?.forEach((item) => {
      emails.push(item?.email);
    });

    const body = {
      entityId,
      entityType,
      emails: emails,
      subject: form?.subject,
      message: form?.message
    };

    showSuccessMes(t('Report is preparing to send.'));
    sendReport.execute(body);
  };

  const dataLoader = useApi({
    name: 'fetching ',
    api: CustomReportApi.getEntityReport,
    onSuccess: () => setResponse(dataLoader?.data),
    onError: (err) => showErrorMes(err)
  });

  const dowloadReport = useApi({
    name: 'dowloadReport',
    api: CustomReportApi.downloadEntityReport,
    autoLoad: false,
    onSuccess: () => downloadExportingFile(dowloadReport?.data, entityType),
    onError: (err) => showErrorMes(err)
  });

  useEffect(() => {
    dataLoader.execute({ entityId, entityType });
  }, [entityId]);

  return (
    <>
      <Modal
        w={'lg'}
        open={isOpen}
        close={() => closeModal}
      >
        <div className="text-sm">
          <Modal.Title>
            <Heading.Group
              text={getArtifactName(projectConfig, entityType) + t(' Report')}
              notCapitalize={true}
              subText={entityData?.code + '     ' + entityData?.name}
              subTextType={'small'}
            >
              <div className="flex gap-4">
                <Button
                  id="testplanningReportModal-sendEmail-button"
                  secondary
                  text={viewText}
                  onClick={() => {
                    if (activeView === 'report') {
                      setActiveView('other')
                      setViewText(t('Report View'))
                    } else {
                      setActiveView('report')
                      setViewText(t('Send Email'))
                    }
                  }}
                  size={'xsmall'}
                />
                <Button
                  id="testplanningReportModal-export-button"
                  secondary
                  text={t('Export')}
                  icon="UploadSimple"
                  onClick={() => {
                    showSuccessMes('Report is downloading');
                    dowloadReport.execute({ entityType, entityId });
                  }}
                  loading={dowloadReport?.loading}
                  size={'xsmall'}
                />
                <Button
                  id="testplanningReportModal-close-button"
                  size={'xsmall'}
                  icon="X"
                  onClick={closeModal}
                />
              </div>
            </Heading.Group>
          </Modal.Title>
          {activeView === 'report' ?
            ( // render report content view
              <Modal.Content h={'lg'}>
                <>
                  <div className=" flex flex-grow flex-col gap-4">
                    <div className="flex flex-grow flex-col">
                      <Heading
                        type={headingSize}
                        text={t('Test Coverage')}
                      />
                      <PercentageBar
                        showLabel={true}
                        datas={getElasticRunStatusBarOptions(response?.testRunStatusInsight)}
                      />
                    </div>
                    <div className="flex flex-grow flex-col gap-4">
                      <Heading
                        notCapitalize="true"
                        type={headingSize}
                        text={t('Tester Executions')}
                      />
                      <TesterExecutionInsight2
                        response={response?.testerExecutionInsight}
                        users={users}
                      />
                    </div>
                    {jiraConfig?.activeDefect === true ? (
                      <>
                        <div className="flex flex-grow flex-col gap-4">
                          <Heading
                            notCapitalize="true"
                            type={headingSize}
                            text={t('testPlanningReportModalTester', { entity: getDefectName(projectConfig) })}
                          />
                          <CreatedByDefectInsight
                            response={response?.createdByDefectInsightByJiraStatus}
                            users={users}
                          />
                        </div>
                        <div className="flex flex-grow flex-col">
                          <Heading
                            notCapitalize="true"
                            type={headingSize}
                            text={t('testPlanningReportModalDefect', { entity: getDefectName(projectConfig) })}
                          />
                          <DefectPriorityAndStatusReport
                            response={response}
                            projectId={projectId}
                            projectConfig={projectConfig}
                            users={users}
                            jiraConfig={jiraConfig}
                            isJiraStatus={true}
                          />
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="flex flex-grow flex-col gap-4">
                          <Heading
                            notCapitalize="true"
                            type={headingSize}
                            text={t('testPlanningReportModalTester', { entity: getDefectName(projectConfig) })}
                          />
                          <CreatedByDefectInsight
                            response={response?.createdByDefectInsightByStatus}
                            users={users}
                          />
                        </div>
                        <div className="flex flex-grow flex-col gap-4">
                          <Heading
                            type={headingSize}
                            text={t('testPlanningReportModalDefect', { entity: getDefectName(projectConfig) })}
                          />
                          <DefectPriorityAndStatusReport
                            response={response}
                            projectId={projectId}
                            projectConfig={projectConfig}
                            users={users}
                            jiraConfig={jiraConfig}
                          />
                        </div>
                      </>
                    )}
                    <div className="flex flex-grow flex-col gap-4">
                      <Heading
                        type={headingSize}
                        text={getDefectName(projectConfig)}
                      />
                      <EntityDataTable
                        response={response?.defects}
                        projectId={projectId}
                        projectConfig={projectConfig}
                        users={users}
                        entityType={ENTITY_TYPES.DEFECT}
                        jiraConfig={jiraConfig}
                      />
                    </div>
                    <div className="flex flex-grow flex-col gap-4">
                      <Heading
                        type={headingSize}
                        text={getRunName(projectConfig)}
                      />
                      <EntityDataTable
                        response={response?.testRuns}
                        projectId={projectId}
                        projectConfig={projectConfig}
                        users={users}
                        entityType={ENTITY_TYPES.TEST_RUN}
                      />
                    </div>
                  </div>
                </>
              </Modal.Content>
            )
            :
            ( // render mail modal view
              <form
                className="flex flex-col gap-4"
                onSubmit={handleSubmit(onSubmitForMail)}
              >
                <Modal.Content h={'lg'}>
                  <>
                    <div className="flex flex-grow flex-col gap-4">
                      <Heading
                        type={headingSize}
                        text={t('Send Report Email')} />

                      <div className="flex gap-4">
                        <MultiSelectDropdownPill
                          label={t('Users')}
                          control={control}
                          name={'emails'}
                          options={users}
                        />
                      </div>
                      <Input
                        name="subject"
                        label={t('Subject')}
                        control={control}
                        maxLength="255"
                        placeholder={t(REPORT_MAIL_SUBJECT)}
                        disabled
                      />
                      <TextArea
                        placeholder={t('Enter your text here')}
                        control={control}
                        name="message"
                        label={t('Message')}
                      />
                    </div>
                  </>
                </Modal.Content>
                <Modal.Buttons>
                  <Button
                    id="reportMailModal-submit-button"
                    primary
                    text={t('Send Report')}
                    type="submit"
                    loading={loading}
                  />
                </Modal.Buttons>
              </form>
            )
          }
        </div>
      </Modal >
    </>
  );
};

export default TestPlanningReportModal;
